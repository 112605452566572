var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-container"},[_c('div',{staticClass:"layout-header"},[_c('span',{staticClass:"hd"},[_vm._v("处理提档")]),_c('div',{staticClass:"ctr clearfix"},[_c('div',{staticClass:"search"},[(_vm.roleStaff['archive/report']!=0)?_c('Input',{attrs:{"placeholder":"文件名/姓名"},on:{"on-enter":function($event){_vm.$router.push({name: 'Search', query: {title: '处理提档', fullPath: encodeURIComponent(_vm.$route.fullPath), keyword: _vm.keyword}})}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}):_vm._e(),_c('Button',{attrs:{"type":"primary","to":{name: 'Search', query: {title: '处理提档', fullPath: encodeURIComponent(_vm.$route.fullPath), keyword: _vm.keyword}},"icon":"md-search"}},[_vm._v("搜索")])],1)])]),_c('div',{staticClass:"layout-body"},[_c('Table',{staticClass:"table",attrs:{"columns":_vm.columns,"data":_vm.items},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-text hover",on:{"click":function($event){return _vm.handleOnExpand(row)}}},[_vm._v(_vm._s(row.applicant_department)+_vm._s(row.applicant)+"申请提取"+_vm._s(row.archive_name)+"档案资料（档案编号"+_vm._s(row.archive_code)+")")])]}},{key:"use_date",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-text",attrs:{"size":"18","color":"#666"}},[_vm._v(_vm._s(row.begin_date)+"~"+_vm._s(row.end_date))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-text"},[_vm._v(_vm._s(row.status == 1 ? '已借出、未归还' : row.status == 2?'已归还':row.status == -1?'已拒绝':'未处理'))])]}},{key:"icon",fn:function(ref){
var row = ref.row;
return [_c('Icon',{attrs:{"type":row._expanded ? 'ios-arrow-down' : 'ios-arrow-back',"size":"18","color":"#666"},on:{"click":function($event){return _vm.handleOnExpand(row)}}})]}}])}),(_vm.items.length > 0)?_c('div',{staticClass:"layout-page tc"},[_c('Page',{attrs:{"total":_vm.obj.total,"size":"small","show-total":"","page-size":20},on:{"on-change":function($event){_vm.page = arguments[0];_vm.handleGetList();}}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }