import tableItem from '../components/table-item'
export default {
    name: 'Archive',

    components: {
        tableItem
    },

    mounted () {
        this.handleGetList();
    },

    methods: {
        // 获取档案记录列表
        handleGetList () {
            if (this.loading) return;
            this.loading = true
            this.$api.archives.archivesHandleList({
                keyword: this.keyword,
                page: this.page,
                page_number: 20
            }).then(res => {
                this.loading = false
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.items = res.list.map(v => {
                    v._expanded = false;
                    v._disableExpand = false
                    v.detail = {}
                    return v;
                })
                this.obj = res.obj;
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        handleOnExpand (row) {
            // if (!status) return;
            // if (this.loading) {
            //     this.items.filter((item, index)=>{
            //         if(item.handle_id == row.handle_id) item._expanded = false;
            //         return item;
            //     });
            //     return;
            // }
            if (row._expanded) {
                this.items.filter((item, index)=>{
                    item._expanded = false;   //其他行关闭
                    item.detail = {}
                    return item;
                });
                return;
            }
            if (this.loading) return;
            this.$Message.loading({content: '请求中...', duration: 0})
            this.loading = true;
            this.$api.archives.archivesHandleDetail({
                handle_id: row.handle_id
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})

                this.items.filter((item, index)=>{
                    if(item.handle_id == row.handle_id) {
                        item.detail = res.obj;
                        item._expanded = true;   //展开选中的行
                    }else{
                        item._expanded = false;   //其他行关闭
                        item.detail = {}
                    }
                    return item;
                });
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                this.$Message.error({content: '请求失败'})
                console.log(err)
            })
        }
    },

    data () {
        return {
            loading: false,
            keyword: '',
            page: 1,
            items: [],
            obj: {
                total: 0,
                total_page: 0,
                page: 0
            },
            expandid: 0,
            columns: [
                {
                    title: '申请提取记录',
                    slot: 'title',
                    minWidth: 200
                },
                {
                    title: '申请时间',
                    key: 'apply_date',
                    width: 160
                },
                {
                    title: '使用时间',
                    slot: 'use_date',
                    width: 250
                },
                {
                    title: '归还时间',
                    key: 'return_date',
                    width: 160
                },
                {
                    title: '状态',
                    slot: 'status',
                    width: 120
                },
                {
                    type: 'expand',
                    width: 50,
                    render: (h, {row}) => {
                        let _ = this;
                        return h(tableItem, {
                            props: {
                                row: row.detail
                            },
                            on: {
                                'on-success' (e) {
                                    _.handleGetList()
                                    eventBus.$emit('on-undohandls')
                                }
                            }
                        })
                    }
                },
                {
                    type: ' ',
                    slot: 'icon',
                    width: 50,

                }
            ],
            roleStaff:JSON.parse(localStorage.getItem('staff_auth_info')),
        }
    }
}