<template lang="pug">
    .table-item
        .item
            label 申请提档用户：
            .text {{row.applicant_department||'-'}}
        .item
            label 申请提档人：
            .text {{row.applicant||'-'}}
        .item
            label 申请提档档案类别：
            .text {{row.first_category||'-'}}
        .item
            label 提取资料：
            .text {{row.file_name}}

        .item
            label 用途：
            .text {{row.message||'-'}}

        .item
            label 使用时间：
            .text {{row.begin_date}} ~ {{row.end_date}}

        .item
            label 档案类型：
            .text {{row.file_type == 1 ? '纸质档案' : '电子档案'}}
        .item(v-if="row.status<0")
            label 经办人（未借出）：
            .text {{row.forbid_handler||'-'}}

        .item(v-if="row.status>0")
            label 经办人（借出）：
            .text {{row.lend_handler||'-'}}
        .item(v-if="row.status!=0&&row.status>0")
            label 归还日期：
            .text(v-if="row.status!=1") {{row.return_date||'-'}}
            DatePicker(v-else :transfer="true"  :options="bbyfOptions" @on-open-change="bbyfOptionsMe"   type="date" format="yyyy-MM-dd" placeholder="请选择日期" style="width: 200px" @on-change="change" v-model="returnDate" :value="returnDate")
        .item(v-if="row.status!=0&&row.status>0")
            label 经办人（归还）：
            .text {{row.return_handler||'-'}}
        br
        .btn(v-if="row.status == '0'")
            Button(type="primary" style="padding: 0 20px;margin-right: 16px;" @click="handleSubmit") 同意借出
            Button(type="primary" style="background:#fff;color:#515a6e;border: 1px solid #D9D9D9;padding: 0 20px;margin-right: 16px;" @click="cancelSubmit(row)") 拒绝
        .btn(v-if="row.status == '1'")
            Button(type="primary" style="padding: 0 20px;margin-right: 16px;" @click="handleReturn") 已归还
            //- Button(style="padding: 0 30px;") 拒绝
</template>
<script type="text/javascript">
export default {
    name: 'tableItem',

    props: {
        row: {
            type: Object,
            default: {}
        }
    },

    methods: {
        // 同意借出
        handleSubmit () {
            if (this.loading) return;

            this.loading = true
            this.$Message.loading({content: '请求中...', duration: 0})
            this.$api.archives.archivesHandle({
                handle_id: this.row.handle_id
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.$Message.success({content: '档案提取成功'})
                this.$emit('on-success')
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                this.$Message.error({content: '档案提取失败'})
                console.log(err)
            })
        },



        change(da){
            this.returnDate=da
        },
        // 点击拒绝
        cancelSubmit(item) {
            console.log(item);
            this.$Modal.confirm({
                title: '提示',
                content: `是否拒绝借出${item.file_name}档案资料`,
                loading: true,
                cancelText:'',
                onOk: () => {
                this.$Modal.remove();
                this.$api.archives.forbid({
                    handle_id: this.row.handle_id,
                }).then(res=>{
                    if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                    this.$Message.success({content: '拒绝成功'})
                    this.$emit('on-success')
                })

                },
                onCancel: () => {
                this.$Modal.remove();
                },
            });

        },
        bbyfOptionsMe(bool){
            let starttime=new Date(this.row.begin_date).getTime()
            this.bbyfOptions= {
                    disabledDate (date) {
                        return  starttime>date.getTime()+86400000||date.getTime()-86400000>(Date.now()-(24 * 60 * 60 * 1000));
                    }
                }
        },
        // 点击已归还
        handleReturn(){

            if(this.returnDate==''){
                this.$Message.warning({content: '请选择归还日期'})
                return;
            }
            this.$api.archives.archivesHandle({
                handle_id: this.row.handle_id,
                handle_date:this.returnDate
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.$Message.success({content: '档案归还成功'})
                this.returnDate=''
                this.$emit('on-success')
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                this.$Message.error({content: '档案归还失败'})
                console.log(err)
            })
        }
    },

    data () {
        return {
            userInfo: _global.userInfo,
            loading: false,
            returnDate:'',
            bbyfOptions:{},
            bbyfOptions5:{
                this_:this,
                disabledDate (date) {
                      return (date && date.valueOf() > Date.now() || date && date.valueOf() < Date.now()-2*365*24*60*60*1000);

                }
            },
        }
    }
}
</script>
<style lang="less" scoped>
.table-item                     {padding: 20px 0;
    .item                       {height: 32px;line-height: 32px;font-size: 14px;margin-bottom: 10px;
                                display: flex;
        label                   {display: block;width: 145px;text-align: right;height: 32px;color: #999;}
        .text                   {color: #343434;}
    }
    .btn                        {padding-left: 120px;}
}
</style>